import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import HeaderSpacer from 'components/layout/header/spacer'
import Content from 'components/new/content'
import Spacer from 'components/new/spacer'
import { GatsbyImage, getSrc } from 'gatsby-plugin-image'
import Button from 'components/button/button'
import H2 from 'components/new/typography/h2'
import H4 from 'components/new/typography/h4'
import P from 'components/new/typography/p'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { StickyContainer, Sticky } from 'react-sticky'
import { fetchJobRequisition, formatJobTypeForSchema } from 'utils/sanity/job-requisitions'
import stripStyles from 'utils/strip-styles'
import formatDate from 'utils/dates/format-date'
import { organizationSchema } from 'data/schema-data'
import SocialShare from 'components/social/blog-social-share'
import JobListingAlertsForm from 'components/forms/job-listing-alerts'
import LocationsMap from 'components/locations-map/locations-map-simple'
import Disclaimer from 'components/landing-page/disclaimer'
import PositionRemovedView from 'views/position-removed-view'
import convertArrayToInlineList from 'utils/convert-array-to-inline-list'

const JobListing = props => {
  const {
    serverData: { error = false },
  } = props

  if (error) {
    return <PositionRemovedView />
  }

  return <JobListingView {...props} />
}

const JobListingView = props => {
  const {
    serverData: {
      jobRequisition: {
        applyUrl: greenhouseApplyUrl,
        careerAreas,
        description,
        gatsbyImageData,
        jobId,
        locations,
        locationsText,
        publishedDate,
        title,
        type,
      },
      meta,
    },
  } = props

  const applyUrl =
    jobId === '4441800006'
      ? 'https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=138d74c6-bad3-4968-99fd-67ed28f4b476&ccId=19000101_000001&jobId=545305&lang=en_US&source=CC2'
      : greenhouseApplyUrl

  const {
    allSanityLocation: { states: allSanityLocationStates, totalCount: locationCount },
    site: {
      siteMetadata: { twitterUsername },
    },
  } = useStaticQuery(graphql`
    {
      allSanityLocation(
        filter: { status: { ne: "permanently-closed" }, retailLocation: { eq: true } }
      ) {
        states: group(field: state) {
          totalCount
          fieldValue
        }
        totalCount
      }
      site {
        siteMetadata {
          twitterUsername
        }
      }
    }
  `)

  allSanityLocationStates.sort((a, b) => b.totalCount - a.totalCount)

  const statesList = allSanityLocationStates.map(state => state.fieldValue)

  const statesListString = convertArrayToInlineList(statesList)

  return (
    <Layout>
      <Helmet>
        <title>
          We're Hiring: {title} #{jobId} | Hutson Inc
        </title>
        <meta property='og:title' content={`We're Hiring: ${title}`} />
        <meta name='description' content={meta.description} />
        <meta property='og:description' content={meta.description} />
        <meta property='og:type' content='article' />
        <meta property='og:url' content={meta.url} />
        <meta property='og:image' content={meta.image} />
        <meta property='twitter:card' content='summary' />
        <meta name='twitter:site' content={twitterUsername} />
        <meta name='twitter:title' content={`We're Hiring: ${title}`} />
        {Array.isArray(meta.schemas)
          ? meta.schemas.map((schema, index) => (
              <script type='application/ld+json' key={index}>
                {schema}
              </script>
            ))
          : null}
      </Helmet>
      <HeroGrid>
        <ImageColumn>
          <GatsbyImage
            image={gatsbyImageData}
            alt=''
            style={{ width: '100%', height: '100%', position: 'absolute' }}
            objectFit='cover'
            objectPosition='50% 50%'
          />
        </ImageColumn>
        <TitleColumn>
          <HeaderSpacer />
          <TitleContainer>
            {Array.isArray(careerAreas) && careerAreas.length > 0 ? (
              <div className='title-section-career-areas'>
                {careerAreas.map((careerArea, index) => (
                  <React.Fragment key={careerArea.handle}>
                    {index !== 0 ? <span>&bull;</span> : null}
                    <CategoryLink to={`/careers/${careerArea.handle}/`}>
                      {careerArea.title}
                    </CategoryLink>
                  </React.Fragment>
                ))}
              </div>
            ) : null}
            <Title>{title}</Title>
            <P className='subtitle'>
              {type === 'John Deere Tech Program' ? (
                <Link to='/careers/tech-program/'>{type}</Link>
              ) : (
                type
              )}
              {locationsText ? (
                <React.Fragment>
                  {' '}
                  &bull; <a href='#locations'>{locationsText}</a>
                </React.Fragment>
              ) : null}
            </P>
            <Spacer size='m' />
            <div>
              <Button as={ButtonA} color='green' ghost href={applyUrl}>
                Apply now
              </Button>
            </div>
          </TitleContainer>
        </TitleColumn>
      </HeroGrid>
      <StyledContent kind='full'>
        <MainColumn>
          <div
            className='description'
            dangerouslySetInnerHTML={{
              __html: stripStyles(description),
            }}
          />
          <div className='description-details'>
            Requisition ID #{jobId} posted {formatDate(new Date(publishedDate))}
          </div>
          <h2>About Hutson, Inc.</h2>
          <p>
            Hutson has seen plenty of changes since its founding back in 1928, but one thing still
            remains the same: our commitment to our customers and employees. From our very first
            location in Mayfield, KY to our {locationCount} locations in {statesListString}, we have
            focused on meeting the needs of our customers and co-workers through integrity and
            innovation.
          </p>
          <p>
            Ultimately companies are really about their people; committed, talented, passionate
            people, who want to be challenged, who want to do something meaningful, realize their
            full potential, make a positive impact in their communities, and have fun doing it.
            That’s exactly what Hutson Inc is about. Our people are passionate individuals who get
            involved and make a difference in the lives of our customers and our communities every
            day. We stand for putting our customers first, treating people with respect, and
            thinking creatively to solve problems and we are excited to welcome you to our team.
          </p>
          <Spacer size='m' />
          <Button as={ButtonLink} color='green' ghost to='/about/'>
            Learn more about Hutson
          </Button>
        </MainColumn>
        <SidebarColumn>
          <SidebarStickyContainer>
            <Sticky>
              {({ style }) => (
                <SidebarStickyInner style={style}>
                  <SidebarDetailsContainer>
                    <SidebarCard>
                      <div className='card-label'>Job Type</div>
                      <H4 as='div' className='card-value'>
                        {type === 'John Deere Tech Program' ? (
                          <Link to='/careers/tech-program/'>{type}</Link>
                        ) : (
                          type
                        )}
                      </H4>
                    </SidebarCard>
                    <SidebarCard>
                      <div className='card-label'>
                        {locations.length > 1 ? 'Locations' : 'Location'}
                      </div>
                      <H4 as='div' className='card-value'>
                        {locations.length !== 0 ? (
                          <a href='#locations'>{locationsText}</a>
                        ) : (
                          'Unspecified'
                        )}
                      </H4>
                    </SidebarCard>
                    <SidebarCard>
                      <div className='card-label'>
                        {careerAreas.length > 1 ? 'Career Areas' : 'Career Area'}
                      </div>
                      <H4 as='div' className='card-value'>
                        {careerAreas.map((careerArea, index) => (
                          <React.Fragment key={careerArea.handle}>
                            {index !== 0 ? <span>&bull;</span> : null}
                            <Link to={`/careers/${careerArea.handle}/`}>{careerArea.title}</Link>
                          </React.Fragment>
                        ))}
                      </H4>
                    </SidebarCard>
                  </SidebarDetailsContainer>
                  <MainSidebarCard>
                    <H4 as='div' className='card-value'>
                      {title}
                    </H4>
                    <Spacer size='s' />
                    <Button as={ButtonA} color='yellow' href={applyUrl}>
                      Apply now
                    </Button>
                  </MainSidebarCard>
                </SidebarStickyInner>
              )}
            </Sticky>
          </SidebarStickyContainer>
        </SidebarColumn>
      </StyledContent>
      <LightBackground>
        <ShareSection xPadding={false} yPadding={false}>
          <H4 as='h2'>Share this position</H4>
          <SocialShare title={title} />
        </ShareSection>
      </LightBackground>
      {locations.length !== 0 ? (
        <div id='locations'>
          <LocationsMap
            locations={locations.map(location => {
              return {
                address: location.address,
                handle: location.handle,
                position: location.geolocation,
                title: location.title,
              }
            })}
          />
        </div>
      ) : null}
      <Content kind='full'>
        <H2>Apply for this position</H2>
        <P>
          Interested in this position? Fill out an application using the link below! If you have any
          questions, reach out to our team at{' '}
          <a href='mailto:recruiting@hutsoninc.com'>recruiting@hutsoninc.com</a>.
        </P>
        <Spacer size='s' />
        <Button as={ButtonA} color='green' ghost href={applyUrl}>
          Apply now
        </Button>
        <Spacer size='l' />
        <Disclaimer>
          In order to provide equal employment and advancement opportunities to all individuals,
          employment decisions at Hutson Inc. will be based on merit, qualifications, and abilities.
          Hutson Inc. provides equal employment opportunities (EEO) to all employees and applicants
          for employment without regard to race, color, religion, gender, sexual orientation,
          pregnancy, gender identity or expression, national origin, age, disability, genetic
          information, marital status, amnesty, retaliation or status as a covered veteran in
          accordance with applicable federal, state and local laws. Hutson Inc. complies with
          applicable state and local laws governing non-discrimination in employment in every
          location in which the company has facilities. Hutson Inc. will make reasonable
          accommodations for qualified individuals with known disabilities unless doing so would
          result in an undue hardship. This policy governs all aspects of employment, including
          selection, job assignment, compensation, discipline, termination, and access to benefits
          and training.
        </Disclaimer>
      </Content>
      <JobListingAlertsForm />
    </Layout>
  )
}

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const HeroGrid = styled.div`
  background-color: ${props => props.theme.color.n10};

  @media (min-width: 900px) {
    ${clearfix}
    display: flex;
    flex-direction: row-reverse;
    min-height: 400px;
  }

  @media (min-width: 1024px) {
    min-height: 500px;
  }
`

const ImageColumn = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
  width: auto;

  @media (min-width: 900px) {
    height: auto;
    overflow: auto;
    padding-bottom: 0;
    ${column('5/12', 0)}
  }

  @media (min-width: 1024px) {
    ${column('7/12', 0)}
  }
`

const TitleColumn = styled.div`
  @media (min-width: 900px) {
    ${column('7/12', 0)}
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    ${column('5/12', 0)}
  }
`

const TitleContainer = styled.div`
  padding: 36px 24px;

  .subtitle {
    color: ${props => props.theme.color.n400};

    a {
      color: ${props => props.theme.color.n400};
    }
  }

  .title-section-career-areas {
    span {
      color: ${props => props.theme.color.n400};
      display: inline-block;
      margin: 0 8px;
    }
  }

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 64px;

    .subtitle {
      display: none;
    }
  }
`

const CategoryLink = styled(Link)`
  color: ${props => props.theme.color.g400};
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 8px 0;
  text-decoration: none;

  :hover,
  :focus {
    text-decoration: underline;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.color.n900};
  font-size: 36px;
  font-weight: 600;
  padding: 0;
  margin: 8px 0 0;

  @media (min-width: 900px) {
    font-size: 48px;
  }
`

const ButtonLink = styled(Link)`
  display: inline-block;
  margin: 0 auto;
  text-decoration: none;
`

const ButtonA = styled(OutboundLink)`
  display: inline-block;
  margin: 0 auto;
  text-decoration: none;
`

const StyledContent = styled(Content)`
  @media (min-width: 900px) {
    display: flex;
  }
`

const MainColumn = styled.div`
  color: ${props => props.theme.color.n900};
  font-size: 18px;
  font-weight: 400;
  margin-right: 0;

  .description {
    > *:first-child {
      margin-top: 0;
    }

    a {
      color: #377539;
      text-decoration: underline;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.75;
    margin: 12px 0 18px;
  }

  strong {
    font-weight: 600;
    vertical-align: bottom;
  }

  ul,
  ol {
    margin: 0 0 0 12px;

    li {
      font-size: 18px;
      line-height: 1.5;
      margin: 12px 0;
      padding: 0;

      a {
        color: #377539;
        text-decoration: none;
        vertical-align: bottom;
        text-decoration: underline;
      }

      p {
        margin: 0;
        padding: 0;
      }
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    max-width: none;
    text-align: left;
    text-transform: none;
  }

  h2 {
    font-size: 28px;
    margin: 36px 0 16px;
  }

  h3 {
    font-size: 24px;
    margin: 20px 0 12px;
  }

  h4 {
    font-size: 21px;
    margin: 18px 0 12px;
  }

  h5 {
    font-size: 20px;
    margin: 18px 0 12px;
  }

  h6 {
    font-size: 18px;
    margin: 18px 0 12px;
  }

  .description-details {
    border-bottom: 1px solid ${props => props.theme.color.n30};
    font-size: 16px;
    color: ${props => props.theme.color.n100};
    margin: 48px 0;
    padding-bottom: 36px;
  }

  #benefits {
    background-color: ${props => props.theme.color.n10};
    margin-top: 36px;
    padding: 36px 24px 24px;

    h2 {
      margin-top: 0;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        background-image: url('/icons/fa-check.svg');
        background-repeat: no-repeat;
        background-position: left 3px;
        background-size: 21px;
        list-style: none;
        padding: 0 0 0 36px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 899.5px), (min-width: 1024px) {
    #benefits {
      ul {
        columns: 2;
        column-gap: ${props => props.theme.size.m};
      }
    }
  }

  @media (min-width: 900px) {
    flex-grow: 2;
    margin-right: 36px;
  }
`

const SidebarColumn = styled.div`
  margin-top: 36px;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    min-width: 300px;
    width: 300px;
  }
`

const SidebarStickyContainer = styled(StickyContainer)`
  flex-grow: 2;
`

const SidebarStickyInner = styled.div`
  padding-top: 16px;
`

const SidebarDetailsContainer = styled.div`
  @media (min-width: 640px) and (max-width: 899.5px) {
    ${clearfix}
    display: flex;
  }
`

const SidebarCard = styled.div`
  background-color: ${props => props.theme.color.n20};
  color: ${props => props.theme.color.n900};
  margin-bottom: 8px;
  padding: 16px;

  .card-label {
    color: ${props => props.theme.color.g400};
    font-weight: 600;
    margin-bottom: 8px;
  }

  .card-value {
    font-weight: 600;

    a {
      color: ${props => props.theme.color.n900};
      font-weight: 600;
      margin: 8px 0 0 0;
      text-decoration: underline;
    }

    span {
      display: inline-block;
      margin: 0 8px;
    }
  }

  @media (min-width: 640px) and (max-width: 899.5px) {
    ${column('1/3', 16)}
  }
`

const MainSidebarCard = styled.div`
  background-color: ${props => props.theme.color.g400};
  color: #fff;
  margin-top: 8px;
  padding: 24px 16px;

  .card-value {
    color: #fff;
    font-weight: 600;
  }

  @media (max-width: 899.5px) {
    margin-top: 16px;
  }
`

const ShareSection = styled(Content)`
  padding: 36px 0;

  h2 {
    margin: 0 0 16px;
  }

  @media (min-width: 600px) {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;

    h2 {
      margin: 0 24px 0 0;
    }
  }

  @media (min-width: 900px) {
    padding: ${props => `${props.theme.size.l} ${props.theme.size.xxl}`};
    width: 100%;
  }
`
/**
 * @param {import('gatsby').GetServerDataProps} context
 */
export const getServerData = async context => {
  const {
    params: { jobId },
  } = context

  try {
    const jobRequisition = await fetchJobRequisition(jobId)

    if (!jobRequisition) {
      return {
        props: {
          error: true,
        },
      }
    }

    const metaUrl = `https://www.hutsoninc.com/careers/jobs/${jobId}/`

    const meta = {
      description: `Apply for the ${jobRequisition.title} position at Hutson${
        jobRequisition.locationsText ? ` in ${jobRequisition.locationsText}` : ''
      }.${jobRequisition.type !== 'Any' ? ` Job type: ${jobRequisition.type}.` : ''}`,
      image: getSrc(jobRequisition.gatsbyImageData),
      schemas: [
        JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [
            {
              '@type': 'ListItem',
              'position': 1,
              'name': 'Hutson Inc',
              'item': 'https://www.hutsoninc.com/',
            },
            {
              '@type': 'ListItem',
              'position': 2,
              'name': 'Careers',
              'item': 'https://www.hutsoninc.com/careers/',
            },
            {
              '@type': 'ListItem',
              'position': 3,
              'name': 'Jobs',
              'item': 'https://www.hutsoninc.com/careers/jobs/',
            },
            {
              '@type': 'ListItem',
              'position': 4,
              'name': jobRequisition.title,
              'item': metaUrl,
            },
          ],
        }),
        JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'JobPosting',
          'description': jobRequisition.description,
          'employmentType': formatJobTypeForSchema(jobRequisition.type),
          'title': jobRequisition.title,
          'datePosted': new Date(jobRequisition.publishedDate).toISOString(),
          'hiringOrganization': organizationSchema,
          'potentialAction': {
            '@context': 'http://schema.org',
            '@type': 'ApplyAction',
            'target': {
              '@context': 'http://schema.org',
              '@type': 'EntryPoint',
              'actionPlatform': jobRequisition.applyUrl,
            },
          },
          ...(Array.isArray(jobRequisition.locations) && jobRequisition.locations.length > 0
            ? {
                jobLocation: jobRequisition.locations.map(location => {
                  return {
                    '@type': 'Place',
                    'address': {
                      '@type': 'PostalAddress',
                      'streetAddress': location.street,
                      'addressLocality': location.city,
                      'addressRegion': location.state,
                      'postalCode': location.zip,
                      'addressCountry': 'US',
                    },
                  }
                }),
              }
            : {}),
        }),
      ],
      url: metaUrl,
    }

    return {
      props: {
        jobRequisition,
        meta,
      },
    }
  } catch (error) {
    console.error(error)
    return {
      props: {
        error: true,
      },
    }
  }
}

export default JobListing
